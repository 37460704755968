  <template>
   <v-app style="max-width:425px; margin-left:auto; margin-right:auto">
    <div>
      <v-row justify="center">
        <v-dialog v-model="isLoadingSignupPage" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break: normal">
              Account Created Successfully. Click ok to Login.
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
             <v-btn
              color="primary"
              text
              @click="redirectToLoginPage"
              >
             OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    </div>
    <div>
      <v-row justify="center">
        <v-dialog v-model="auth_error_enable" persistent max-width="290">
          <v-card>
            <v-card-title style="word-break: normal">
              {{errorMessage}}
            </v-card-title>
            <v-spacer></v-spacer>
            <div v-if="retries > 0 || customerUnauthorized" style="padding-bottom: 15px;">
              <div>
                <v-btn class="primary" text @click="closeDialogError" >
                  {{ $t("Warning_Message.Cancel") }}
                </v-btn>
              </div>
            </div>
            <div v-else style="display: flex; justify-content: space-around; padding-bottom: 15px;">
              <div>
                <v-btn class="primary" text @click="gotoLoginPage">
                  {{ $t("Login.LoginPage.Go_Back") }}
                </v-btn>
              </div>
              <div>
                <v-btn class="primary" text @click="resendOTP">
                  {{ $t("Login.LoginPage.Resend_OTP") }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
     <div class="container-fluid OTPPageClass" style="margin:20px;">
       <div align="left" style="margin-top: 15%;">
        <!-- <div style = "position:absolute; margin-left: 10%; margin-top: -1%;" align="right" class="LanguageSelect">
          <v-select
            style="width:20%;"
            :items="LanguageList"
            v-model="Language"
            item-text="item-text"
            item-value="item-value"
            @change="changeLanguage"
            >
            <template v-slot:selection="{ item, index }">
              <img :src="item.image">
           </template>
          </v-select>
        </div> -->
         <span style="font-size: 20px; line-height: 32px; font-weight: 600">{{ $t("Login.OTPSignupPage.OTP_Authentication") }}</span>
         <p v-if="$store.state.locale =='en'" style ="font-size:14px;text-align:left;margin-top:16px;">{{ $t("Login.OTPSignupPage.Auth_Message") }} (+95) <span style="color:green">{{phone_number}}</span> </p>
         <p v-else style="font-size: 14px; text-align: left; margin-top: 16px;">{{ $t("Login.OTPSignupPage.Auth_Message") }} (+95) <span style="color:green">{{phone_number}}</span> {{ $t("Login.OTPSignupPage.Auth_second") }}</p>
       </div>
       <div style="display: flex; flex-direction: row wrap;margin-top:48px;" align="left">
        <v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input InputCLass" @keyup="moveNext(inputOTP1,1)" v-model.number="inputOTP1" ref="ref1"/>
        <v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input InputCLass" @keyup="moveNext(inputOTP2,2)" v-model.number="inputOTP2" ref="ref2"/>
        <v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input InputCLass" @keyup="moveNext(inputOTP3,3)" v-model.number="inputOTP3" ref="ref3"/>
        <v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input InputCLass" @keyup="moveNext(inputOTP4,4)" v-model.number="inputOTP4" ref="ref4"/>
        <v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input InputCLass" @keyup="moveNext(inputOTP5,5)" v-model.number="inputOTP5" ref="ref5"/>
        <v-text-field pattern="([1234567890])\w{0}" maxlength = "1" class="centered-input InputCLass" @keyup="moveNext(inputOTP6,6)" v-model.number="inputOTP6" ref="ref6"/>
      </div>
      <div style="padding: 12px;">
        <span> {{ $t("Login.LoginPage.Didnt_Receive_OTP") }} </span>
        <v-btn
        elevation=""
        class="signup"
        color="primary"
        large
        v-bind:disabled="isOTPSent || isButtonClicked"
        style="width: 80%; margin: 12px;"
        @click="resendOTP"
        >
          {{ $t("Login.LoginPage.Resend_OTP") }}
        </v-btn>
        <div v-if="isOTPSent">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p> {{ $t("Login.LoginPage.Sending_OTP") }}! </p>
        </div>
        <p style="padding: 10px;" v-if = "sentMessage && (!inputOTP1 && !inputOTP2 && !inputOTP3 && !inputOTP4 && !inputOTP5 && !inputOTP6) "> {{ $t("Login.LoginPage.OTP_Has_Been_Sent_Again") }} </p>
      </div>
      <div style="margin-top:30px;">
        <div v-if="isButtonClicked">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p>{{ $t("Login.LoginPage.Signing_You_In") }}</p>
        </div>
      </div>
   </div>
</v-app>

</template>

<script>
  import axios from "axios";
  import Vuetify from 'vuetify'
  export default {
    data() {
      return {
        phone_number: "",
        fullName: "",
        dateOfBirth: "",
        errorMessage: "",
        auth_error_enable: false,
        email : "",
        nrc : "",
        isSmoker : "",
        isAlcoholic : "",
        gender : "",
        height : "",
        weight : "",
        LanguageList: [{"item-text":"English","item-value":"en","image":"https://d3vsnl8idgwrlw.cloudfront.net/usa_flag.svg"},{"item-text":"Burmese","item-value":"mm","image": "https://d3vsnl8idgwrlw.cloudfront.net/myanmar_flag.svg"}],
        Language: "",
        isButtonClicked: false,
        alertUnmatched: false,
        isLoadingSignupPage: false,
        inputOTP1: "",
        inputOTP2: "",
        inputOTP3: "",
        inputOTP4: "",
        inputOTP5: "",
        inputOTP6: "",
        isOTPSent: false,
        sentMessage: false,
        retries: -1,
        customerUnauthorized: false
      }
    },
    methods: {
      gotoLoginPage()
      {
        this.$router.push({
          name: "Login"
        });
      },
      resendOTP() {
        this.auth_error_enable = false;
        this.isOTPSent = true;
        this.sentMessage = false;
        var otpBody = {
          phoneNumber: this.phone_number
        };
        axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",otpBody)
            .then((sendOTPResponse) =>{
              this.isOTPSent = false;
              this.sentMessage = true;
              this.inputOTP1 = "";
              this.inputOTP2 = "";
              this.inputOTP3 = "";
              this.inputOTP4 = "";
              this.inputOTP5 = "";
              this.inputOTP6 = "";
              this.$refs["ref1"].$refs.input.focus();
            })
            .catch((sendOTPError) =>{
              this.error_enable = true;
            });
      },
      closeDialogError(){
        if(this.customerUnauthorized)
        {
          this.$router.push({
            name: "Login"
          });
        }
        else
        {
          this.auth_error_enable = false;
          this.inputOTP1 = '';
          this.inputOTP2 = '';
          this.inputOTP3 = '';
          this.inputOTP4 = '';
          this.inputOTP5 = '';
          this.inputOTP6 = '';
        }
      },
      moveNext(value,current_place)
      {
        this.sentMessage = false;
        if(value <= 9 && typeof value == "number"){
          if(current_place != 6)
          {
            this.$refs["ref"+(parseInt(current_place)+1).toString()].$refs.input.focus()
          }
          else if(current_place == 6)
          {
            this.LoginWithOTP();
            this.$refs["ref1"].$refs.input.focus()
          }
        }
        else if(value == "")
        {
          if(current_place !=1)
          {
            this["inputOTP"+(parseInt(current_place)-1).toString()] = "";
            this.$refs["ref"+(parseInt(current_place)-1).toString()].$refs.input.focus();
          }
        }
        // else
        // {
        //   console.log("idk what to do here");
        // }
      },
      changeLanguage(){
        this.$i18n.locale = this.Language;
        this.$store.dispatch("changeLocale", this.$i18n.locale);
      },
      LoginWithOTP()
      {
        this.isButtonClicked = true;
        // this.$router.push({
        //   name: 'CreatePassword',
        //   params: {
        //     'phone_number': this.phone_number,
        //     'fullName': this.fullName,
        //     'dateOfBirth': this.dateOfBirth,
        //     'email' : this.email,
        //     'nrc' : this.nrc,
        //     'alcoholic': this.isAlcoholic,
        //     'smoker': this.isSmoker,
        //     'gender' : this.gender,
        //     'height' : this.height,
        //     'weight' : this.weight
        //   }
        var otp_body = {
          otpProvided: this.inputOTP1.toString()+this.inputOTP2.toString()+this.inputOTP3.toString()+this.inputOTP4.toString()+this.inputOTP5.toString()+this.inputOTP6.toString(),
          phoneNumber: this.phone_number
        };
        var customerLoginData = {
          otpProvided: this.inputOTP1.toString()+this.inputOTP2.toString()+this.inputOTP3.toString()+this.inputOTP4.toString()+this.inputOTP5.toString()+this.inputOTP6.toString(),
          mobileNumber: this.phone_number
        };
        axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/customerSignIn",
          customerLoginData
          )
        .then((customerLoginData) => {
          this.isLoading = false
          this.$cookies.set("customerToken", customerLoginData.data.token,"1w");
          this.$cookies.set("access",customerLoginData.data.accessToken);
          let redirectionFlow = localStorage.getItem('redirectionFlow')
            if (redirectionFlow) {
              localStorage.removeItem('redirectionFlow');
              let path = localStorage.getItem('redirectionURL');
              localStorage.removeItem('redirectionURL');
              this.$router.push({
              path: path
            });
          } else {
            this.$router.push({
            path: "/customer/teleconsultationHome",
          });
          }

        })
        .catch((customerLoginException) => {
          this.isLoginClicked = false
          this.isLoading = false
         if(customerLoginException.response.status == 400 || customerLoginException.response.status == 303 || customerLoginException.response.status == 401){
               this.auth_error_enable = true;
               this.errorMessage = "You do not have a registered account";
               this.customerUnauthorized = true;
         }
         else if(customerLoginException.response.status == 306){
            this.auth_error_enable = true;
            this.retries = customerLoginException.response.data.retries_left;
            if(this.$i18n.locale == "en" && this.retries > 0)
              this.errorMessage = "Invalid OTP";
            else if(this.$i18n.locale == "mm" && this.retries > 0)
              this.errorMessage = "OTP နံပါတ်မှားနေသည်";
            else if(this.$i18n.locale == "en" && this.retries == 0)
              this.errorMessage = "OTP Expired, please generate again";
            else
              this.errorMessage = "OTP အချိန်ကုန်သွားပါပြီ။ နောက်တစ်ကြိမ်ထပ်မံ ကြိုးစားပါ";
         }
         this.isButtonClicked = false;
         this.isOTPSent = false;
         this.inputOTP1 = "";
         this.inputOTP2 = "";
         this.inputOTP3 = "";
         this.inputOTP4 = "";
         this.inputOTP5 = "";
         this.inputOTP6 = "";
        });
      },
      closeDialogUnauthorized()
      {
        this.$router.push({
          path:'/signup'
        });
      },
      redirectToLoginPage(){
        this.$router.push({
          name : "Login"
        });
      },
    },
    mounted() {
      this.$i18n.locale = this.$store.state.locale != "" && this.$store.state.locale ? this.$store.state.locale : 'mm';
      this.Language = this.$i18n.locale;
      this.phone_number = this.$route.params.phone_number;
      if(this.phone_number == "" || this.phone_number === undefined)
      {
        this.$router.push({
          name: 'Login'
        });
      }
    }
  };
</script>

<style scoped>

.font_size_12_400{
  font-size: 14px;
  font-weight:  400;
}
.mx-input{
  height: 40px !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: none!important;
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
}
.centered-input :deep(input) {
  text-align: center;
}
.centered-input {
  padding: 10px;
}
/* @media (min-width: 800px){
  .OTPPageClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
  .LanguageSelect{
    margin-left: 25% !important;
  }
  .InputCLass{
    width: 15% !important;
  }
  } */
</style>